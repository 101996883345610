<div [formGroup]="form">
  <div formArrayName="options" class="p-3" [class.selector-grid]="!long">
    <div
      [class.justify-content-center]="!long"
      [class.justify-content-start]="long"
      [class.text-start]="long"
      [ngClass]="long ? 'row checkbox-container-row d-block' : 'checkbox-container-col'"
      *ngFor="let option of options.controls; index as i; last as last"
    >
      <div *ngIf="!text[i]?.includes(glutenFreeName) || (text[i]?.includes(glutenFreeName) && mode === 'kms')">
        <div [class.pb-5]="last && long">
          <span
            [class.disabled-modifier]="
              (enzoName && enzoName === text[i] && !showEnzoItem) ||
              ((optionGroup?.options)[i] | notAvailableWithEnzoCheck: enzoSelected)
            "
          >
            <label class="containerChk" [ngClass]="long ? 'rowCheck ms-4' : 'colCheck'">
              <input type="checkbox" [formControl]="option" [formControlName]="i" />
              <span class="checkmarkChk">
                <lib-svg-sprite svgID="checkmarkIcon"></lib-svg-sprite>
              </span>
              <p [ngClass]="long ? 'p-0 modifierText' : 'modifierText'">
                <span *ngIf="long">With </span>
                {{ text[i] }}
                <span *ngIf="long"> {{ cost[i] > 0 ? (cost[i] | currency) : '' }}</span>
              </p>
              <ng-container *ngIf="selectedItems[i]?.name.replace(text[i], '').trim() !== 'Add'">
                <div class="addition-text" *ngIf="selectedItems[i]">
                  {{ selectedItems[i]?.name.replace(text[i], '').trim() }}
                </div>
              </ng-container>
              <p class="modifierPriceText" *ngIf="!long">
                {{ cost[i] > 0 ? (cost[i] | currency) : '' }}
              </p>
            </label>
          </span>
          <span
            *ngIf="enzoName && enzoName === text[i] && !showEnzoItem"
            class="modifierText me-3"
            style="color: #f04e29"
            >(SOLD OUT)</span
          >
        </div>
      </div>

      <div *ngIf="text[i]?.includes(glutenFreeName) && mode !== 'kms'" [class.pb-5]="last && long">
        <label class="containerChk" [ngClass]="long ? 'rowCheck ms-4' : 'colCheck'">
          <input
            *ngIf="isSelected"
            [formControl]="option"
            [formControlName]="i"
            type="checkbox"
            style="margin-bottom: 10px"
          />
          <span class="checkmarkChk" *ngIf="isSelected">
            <lib-svg-sprite svgID="checkmarkIcon"></lib-svg-sprite>
          </span>
          <p class="modifierPriceText" *ngIf="long" (click)="shiftSelect($event)">
            {{ text[i] }}
            <span *ngIf="long"> {{ cost[i] > 0 ? (cost[i] | currency) : '' }}</span>
          </p>
          <ng-container *ngIf="selectedItems[i]?.name.replace(text[i], '').trim() !== 'Add'">
            <div class="addition-text" *ngIf="selectedItems[i]">
              {{ selectedItems[i]?.name.replace(text[i], '').trim() }}
            </div>
          </ng-container>
          <p class="modifierPriceText" *ngIf="!long">
            {{ cost[i] > 0 ? (cost[i] | currency) : '' }}
          </p>
        </label>
      </div>

      <span class="modifyModifierText" *ngIf="!long && subModifiers?.length > 1" (click)="modifyClicked(i)">
        Modify
      </span>
    </div>
  </div>
</div>
