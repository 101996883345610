import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { InternalDispatchedActionResults } from '@ngxs/store/src/internal/dispatcher';
import { OptionGroup } from '@lib/models/olo.optiongroup';
@Component({
  selector: 'lib-checkbox',
  templateUrl: './checkbox.component.html',
  styles: []
})
export class CheckboxComponent {
  @Input() values: string[];
  @Input() form: UntypedFormGroup;
  @Input() options: UntypedFormArray;
  @Input() text: string[];
  @Input() modifierID: number[];
  @Input() cost: number[];
  @Input() long: boolean;
  @Input() subModifiers: any[];
  @Input() selectedItems: any[];
  @Input() mode: string;
  @Input() showEnzoItem: boolean;
  @Input() enzoName: string;
  @Input() glutenFreeName: string;
  @Input() enzoSelected: boolean;
  @Input() optionGroup: OptionGroup;

  @Output() modifyEmit = new EventEmitter<any>();
  showitem = false;

  glutenFree: '';
  isSelected: boolean = false;

  constructor() {}

  modifyClicked(index: number) {
    this.modifyEmit.emit(index);
  }

  shiftSelect(event: any) {
    if (event.shiftKey) {
      this.isSelected = true;
    }
  }
}
