import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { ModeService } from '@app/providers/mode.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { GuestInfoModalComponent } from '@app/components/guest-info-modal/guest-info-modal.component';
import { BillingInfo } from 'src/assets/chepri-modules/src/models/olo.billinginfo';
import {
  SetBillingInfo,
  EmptyPreviousBasket,
  CreateBasket,
  GetTimeSlots,
  SetHandoffMode
} from '@app/store/actions/basket.action';
import { Select, Store } from '@ngxs/store';
import { SetPartySize } from '@app/store/actions/user.action';
import { SoldoutModalComponent } from 'src/assets/chepri-modules/src/lib/soldout-modal/soldout-modal.component';
import { GetMenu, GetRestaurantCapacity, GetCalendars } from '@app/store/actions/restaurant.action';
import { DirectusUserService } from '@app/providers/expo/directus/directus-user.service';
import { Router } from '@angular/router';
import { TrancloudProviderService } from '@app/datacap-trancloud/services/trancloud-provider.service';
import { DirectusExpoProvider } from '@app/providers/expo/directus/directus-expo.provider';
import { FormBuilder } from '@angular/forms';
import { DirectusCurrentOrder, SelectableTime, TimeSlot } from '@app/models/capacity.model';
import { WebSocketsService } from '@app/providers/expo/websockets/websockets.service';
import { interval, Observable, Subject, Subscription } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { CapacityService } from '@app/providers/capacity.service';
import * as moment from 'moment';
import { DirectusApiService } from '@app/providers/expo/directus/directus-api.service';
import { HeatmapLocationInterface } from '@app/models/heatmap-location.interface';
import { RestaurantService } from '@app/providers/restaurant.service';
import { DateTime } from '../../../../../assets/chepri-modules/src/models/DateTime';
import { DirectusProvider } from '@app/providers/directus.provider';
import { take } from 'rxjs/operators';
import { CmsService } from '@app/providers/cms.service';
import { DailyCapacity } from '@app/models/directus.collections';
import { environment } from '@env/environment';
import { GlobalStateModel } from '@app/store/state.model';
import { DirectusSchema, DirectusUsers } from '@app/providers/expo/directus/directus-collections.interface';
import { DirectusUser } from '@directus/sdk';
const later = require('@breejs/later');

@Component({
  selector: 'app-kiosk-expo-start-order',
  templateUrl: './start-order.component.html',
  styleUrls: ['./start-order.component.scss']
})
export class StartOrderComponent implements OnDestroy {
  heatmapLocations: HeatmapLocationInterface[] = [];
  isLoading: string;
  hasScrolled = false;
  subscriptions: Subscription[] = [];
  locationSelectValue = 'ALL_LOCATIONS';
  @Select((state: any) => state.user.isMaster) isMaster$: Observable<number>;

  private interval: any;
  private unsubscribe: () => void;
  private intervalSubject: Subject<void>;

  constructor(
    private router: Router,
    private modeService: ModeService,
    private modalService: NgbModal,
    private store: Store,
    private directusUser: DirectusUserService,
    private directusExpo: DirectusExpoProvider,
    private directusApi: DirectusApiService,
    private webSocket: WebSocketsService,
    private capacity: CapacityService,
    private restaurant: RestaurantService,
    private directus: DirectusProvider,
    private cms: CmsService,
    private cdr: ChangeDetectorRef
  ) {
    this.modeService.setApplicationMode('kms');
    this.directusUser.getCurrentUser(this.directusExpo.getStoredDirectusID()).subscribe(user => {
      const finishInit = (usersData: DirectusUser<DirectusSchema>[]) => {
        // usersData is a list of all locations we want to track (as Directus users)
        console.log('usersData', usersData);
        usersData.forEach((userData, i: number) => {
          // if (userData.role !== 1) {
          let heatmapLocation: HeatmapLocationInterface = {
            restaurantID: userData.location_olo_id,
            locationRestaurantId: parseInt(userData.location_restaurant_id, 10),
            capacityBuckets: [],
            timeStamps: [],
            locationName: userData.first_name + ' ' + userData.last_name,
            nextHourQuant: 0,
            restOfDayQuant: 0,
            upcomingSlots: [],
            showAsMaster: userData.show_as_master,
            gfLeft: 0
          };
          if (Number(userData.role as string) === 1) {
            // admin
            heatmapLocation = {
              restaurantID: '0',
              locationRestaurantId: 0,
              capacityBuckets: [],
              timeStamps: [],
              locationName: userData.first_name + (userData.last_name === ' ' ? '' : ' ' + userData.last_name),
              nextHourQuant: 0,
              restOfDayQuant: 0,
              upcomingSlots: [],
              showAsMaster: false,
              gfLeft: 0
            };
          }
          this.heatmapLocations.push(heatmapLocation);
          this.store.dispatch(new GetMenu(heatmapLocation.restaurantID.toString())).subscribe(res => {
            this.store.dispatch(new GetRestaurantCapacity(res.restaurant.restaurant.id)).subscribe(() => {
              this.store.dispatch(new GetCalendars(res.restaurant.restaurant.id, false)).subscribe(calres => {
                this.setCapacityBuckets(calres, res.restaurant.restaurant.id, i);
                // this.webSocket.connect();
                // this.subscriptions.push(
                //   this.webSocket.socket$.subscribe(
                //     webres => {
                //       this.setCapacityBuckets(calres, res.restaurant.restaurant.id, i);
                //     },
                //     error => {
                //       console.log(error);
                //     }
                //   )
                // );
                // const cron = later.parse.cron('* * * * *');
                // this.interval = later.setInterval(() => this.setCapacityBuckets(calres, res.restaurant.restaurant.id, i), cron);
                if (user.role === '07fbb946-5744-44d6-b805-302fd48b261f') {
                  this.subscriptions.push(
                    interval(30000).subscribe(() => {
                      this.setCapacityBuckets(calres, res.restaurant.restaurant.id, i);
                    })
                  );
                } else {
                  this.subscriptions.push(
                    interval(5000).subscribe(() => {
                      this.setCapacityBuckets(calres, res.restaurant.restaurant.id, i);
                    })
                  );
                }
              });
            });
          });
          // }
        });
      };

      if (user.role === '07fbb946-5744-44d6-b805-302fd48b261f') {
        this.directusApi.getUsers().subscribe(users => {
          finishInit(users.filter(x => x.location_olo_id !== null));
        });
      } else {
        finishInit([user]);
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
    if (this.interval) {
      clearInterval(this.interval);
    }
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  ribbonScroll(event: Event) {
    let el = event.srcElement as HTMLElement;
    if (el.scrollLeft > 0) {
      this.hasScrolled = true;
    } else {
      this.hasScrolled = false;
    }
  }

  scrollBack() {
    let el = document.getElementById('ribbon-scroll');
    el.scrollLeft = 0;
  }

  setCapacityBuckets(state: any, id: string, i: number) {
    console.log('running setCapacityBuckets');
    this.cdr.detectChanges();
    // Get Locations orders
    this.subscriptions.push(
      this.capacity.getOrders(id, false).subscribe((orders: DirectusCurrentOrder[]) => {
        // Set Daily and Hourly Sales
        this.setHourandDailySales(orders, i);

        // Set Heatmap
        this.setHeatmapSliderTiles(orders, id, i, state);

        this.capacity
          .checkEnzoAvailability(Number(id))
          .pipe(take(1))
          .subscribe((amountLeft: number) => {
            this.heatmapLocations[i].gfLeft = amountLeft;
          });

        // Set Next available times for 1 - 6 pizzas
        this.isMaster$.subscribe(isMaster => {
          if (isMaster) {
            this.cms.getLocation(this.heatmapLocations[i].restaurantID).subscribe(loc => {
              // let user_capacity_settings: DailyCapacity[] = loc.capacity_days;
              // const settings: any = await this.directus
              // .clearQueries()
              // .setItems('capacity_settings')
              // .setFilters({
              //   column: 'location.olo_id',
              //   comparison: 'eq',
              //   value: this.heatmapLocations[i].restaurantID
              // })
              // .setSingle(true)
              // .setFields('*.*.*')
              // .get();
              // if (settings.data) {
              //   user_capacity_settings = settings.data;
              // }
              this.restaurant.getCalendars(this.heatmapLocations[i].restaurantID).subscribe(res => {
                const end = res.calendar[0].ranges[0].end;
                this.heatmapLocations[i].upcomingSlots = this.capacity.getNextAvailableTimes(loc, end, orders, false);
              });
            });
          } else {
            this.heatmapLocations[i].upcomingSlots = this.capacity.getNextAvailableTimes(
              state.restaurant.capacity_settings,
              state.restaurant.calendar.calendar[0].ranges[0].end,
              orders,
              false
            );
            console.log('this.heatmapLocations[i].upcomingSlots', this.heatmapLocations[i].upcomingSlots);
          }
        });
      })
    );
  }

  formatStoreTime(res: any) {
    let locStoreTime = '';
    const storeTime = res.calendar;
    if (storeTime.length > 0) {
      const start = DateTime.fromOlo(storeTime[0].ranges[0].start).time;
      const end = DateTime.fromOlo(storeTime[0].ranges[0].end).time;
      locStoreTime = start + ' - ' + end;
    } else {
      locStoreTime = 'olo not set up yet';
    }
    return locStoreTime;
  }

  setHeatmapSliderTiles(orders: DirectusCurrentOrder[], id: string, i: number, state: any) {
    this.isMaster$.subscribe(async isMaster => {
      if (isMaster) {
        this.cms.getLocation(this.heatmapLocations[i].restaurantID).subscribe(loc => {
          // let user_capacity_settings: any = null;
          // const settings: any = await this.directus
          // .clearQueries()
          // .setItems('capacity_settings')
          // .setFilters({
          //   column: 'location.olo_id',
          //   comparison: 'eq',
          //   value: this.heatmapLocations[i].restaurantID
          // })
          // .setSingle(true)
          // .setFields('*.*.*')
          // .get();
          // if (settings.data) {
          //   user_capacity_settings = settings.data;
          // }
          this.restaurant.getCalendars(this.heatmapLocations[i].restaurantID).subscribe(res => {
            const end = res.calendar[0].ranges[0].end;
            this.heatmapLocations[i].capacityBuckets = this.capacity.getHeatmapData(loc, end, orders);
          });
        });
      } else {
        this.heatmapLocations[i].capacityBuckets = this.capacity.getHeatmapData(
          state.restaurant.capacity_settings,
          state.restaurant.calendar.calendar[0].ranges[0].end,
          orders
        );
      }
    });
    this.heatmapLocations[i].timeStamps = [];
    this.heatmapLocations[i].capacityBuckets.forEach((slot: SelectableTime, i2: number) => {
      if (i2 === 0 || Number.isInteger(i2 / 3)) {
        this.heatmapLocations[i].timeStamps.push(slot.time.moment.format());
      }
    });
  }

  setHourandDailySales(orders: DirectusCurrentOrder[], i: number) {
    let dailyQuant = 0;
    let nextHourQuant = 0;
    orders.forEach(order => {
      const readyTime = moment(order.ready_time);
      if (readyTime.isBefore(moment().add(1, 'h'))) {
        nextHourQuant = nextHourQuant + order.pizza_quant;
      }
      dailyQuant = dailyQuant + order.pizza_quant;
    });
    this.heatmapLocations[i].restOfDayQuant = dailyQuant;
    this.heatmapLocations[i].nextHourQuant = nextHourQuant;
  }

  guestInfoModal(type: string, i: number) {
    const guestInfoModal = this.modalService.open(GuestInfoModalComponent, {
      centered: true,
      keyboard: false,
      backdrop: 'static'
    });
    guestInfoModal.componentInstance.type = type;
    guestInfoModal.componentInstance.xClicked.subscribe(() => {
      this.modalService.dismissAll();
    });
    guestInfoModal.componentInstance.startOrderClicked.subscribe((guestInfo: any) => {
      const billingInfo = new BillingInfo();
      billingInfo.firstname = guestInfo.firstName;
      billingInfo.lastname = guestInfo.lastName;
      billingInfo.emailaddress = environment.kioskDefaultEmail;
      billingInfo.contactnumber = environment.kioskDefaultPhone;
      billingInfo.usertype = 'guest';
      this.store.dispatch(new SetBillingInfo(billingInfo)).subscribe(() => {
        if (guestInfo.partySize) {
          this.store.dispatch(new SetPartySize(guestInfo.partySize + 1));
        }
        console.log('index', i, this.heatmapLocations[i]);
        this.startOrder(type, this.heatmapLocations[i].restaurantID);
      });
    });
  }

  startOrder(orderType: string, restaurantID: string) {
    this.store.dispatch(new EmptyPreviousBasket());
    if (orderType === 'pickup') {
      this.store.dispatch(new CreateBasket(restaurantID.toString())).subscribe(res => {
        this.store.dispatch(new GetCalendars(res.restaurant.restaurant.id, false)).subscribe(res => {
          this.store.dispatch(new GetTimeSlots(true)).subscribe(res => {
            if (res.basket.timeSlots.length === 0) {
              this.openSoldOutModal();
            } else {
              this.router.navigateByUrl(`/kiosk/menu/${restaurantID}`);
              this.modalService.dismissAll();
            }
          });
        });
      });
    } else if (orderType === 'dinein') {
      this.store.dispatch(new CreateBasket(restaurantID.toString())).subscribe(res => {
        this.store.dispatch(new SetHandoffMode('dinein'));
        this.store.dispatch(new GetCalendars(res.restaurant.restaurant.id, false)).subscribe(res => {
          this.store.dispatch(new GetTimeSlots(true)).subscribe(res => {
            if (res.basket.timeSlots.length === 0) {
              this.openSoldOutModal();
            } else {
              this.router.navigateByUrl(`/kiosk/menu/${restaurantID}`);
              this.modalService.dismissAll();
            }
          });
        });
      });
    }
  }

  openSoldOutModal() {
    let modalRef;
    const modalOptions: NgbModalOptions = { centered: true, size: 'lg', windowClass: 'hideScroll' };
    modalRef = this.modalService.open(SoldoutModalComponent, modalOptions);
    modalRef.componentInstance.xClicked.subscribe(() => {
      this.modalService.dismissAll();
    });
    modalRef.componentInstance.continueClicked.subscribe(() => {
      this.router.navigateByUrl('/menu');
      this.modalService.dismissAll();
    });
  }

  locationSelectChange(event: any) {
    this.locationSelectValue = event.target.value;
  }

  // for example if current time is 4:06, then "4:10 PM" -> "4 MINUTES", and "4:40 PM" -> "34 MINUTES"
}
