import { Pipe, PipeTransform } from '@angular/core';
import { Option } from '@lib/models/olo.option';

@Pipe({
  name: 'notAvailableWithEnzoCheck',
  pure: false
})
export class NotAvailableWithEnzoCheckPipe implements PipeTransform {
  transform(option: Option, enzoSelected: boolean): boolean {
    return enzoSelected && !!option.metadata?.find(m => m.key === 'allowedWithGluten' && m.value === 'false');
  }
}
