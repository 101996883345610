import { Component, OnInit, Input, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormArray } from '@angular/forms';
import { ProductService } from '../../providers/product.service';
import { OptionGroup } from '../../models/olo.optiongroup';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { trigger, transition, animate, style } from '@angular/animations';
import { Store } from '@ngxs/store';

// <label style="flex-direction: column;display: inline-flex;justify-content: flex-start; align-items: center;">
// <lib-radio-button [form]="form"
// formControlName="options"
// [value]="groupOption.id"
// [customizeRadio]="true"></lib-radio-button>
// <input
// type="radio"
// [value]="groupOption.id"
// formControlName="option"
// style="margin-bottom: 10px">
//   {{groupOption.name}}

@Component({
  selector: 'lib-modifier-selector',
  templateUrl: './modifier-selector.component.html',
  styles: [],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateY(-100%)' }),
        animate('200ms ease-in', style({ transform: 'translateY(0%)' }))
      ]),
      transition(':leave', [animate('200ms ease-in', style({ transform: 'translateY(-100%)' }))])
    ])
  ]
})
export class ModifierSelectorComponent implements OnInit, OnDestroy {
  @Input() optionGroup: OptionGroup;
  @Input() masterSelected: any;
  @Input() customizeRadio: boolean;
  @Input() isEdit: boolean;
  @Input() mode: string;
  @Input() recursive = false;
  @Input() showEnzoItem: boolean;
  @Input() enzoName: string;
  @Input() glutenFreeName: string;
  @ViewChild('content') content: ElementRef;

  form: UntypedFormGroup;
  oneChoice: boolean;
  editSelectedGroup: any;

  formVals: number[];
  formText: string[];
  formIds: number[];
  formCosts: number[];
  subModifiers: any[];
  modifierGroup: any[];
  subs: any = [];
  isSaladSize: boolean;
  selectedSubModifier: number;
  selectedItems: any[] = [];
  isChecked = true;

  enzoSelected = false;

  constructor(private service: ProductService, private modalService: NgbModal, private store: Store) {}

  ngOnInit() {
    this.oneChoice =
      this.optionGroup.maxselects === '1' ||
      this.optionGroup.description.toLowerCase() === 'primary cheese selection' ||
      this.optionGroup.description.toLowerCase().includes('size');
    if (this.optionGroup.description.toLowerCase() === 'customize') {
      this.form = this.createCustomizeForm(this.optionGroup);
      this.updateAfterFormInit();
    } else {
      this.form = this.toFormGroup(this.optionGroup);
    }
    this.isSaladSize =
      this.optionGroup.description.toLowerCase().includes('size') &&
      this.optionGroup.options.some(o => o.modifiers.some(m => m.description.toLowerCase().includes('customize')));
    this.subscribeToSelected();
    this.subscribeToFormChanges();
  }

  ngOnDestroy() {
    // Unsubscribe from all
    this.subs.forEach((sub: any) => sub.unsubscribe());
  }

  updateAfterFormInit() {
    const arr = this.service.getSelected();
    // Update array to send down what submodifier was selected
    const subMods: any[] = [];
    const addditional = this.optionGroup.options.find(el => el.name.toLowerCase().includes('additional'));
    if (addditional) {
      addditional.modifiers.forEach(innerGroup => {
        let submodifier: any;
        innerGroup.options.forEach(mod => {
          if (arr.includes(mod.id)) {
            submodifier = mod;
          }
        });
        subMods.push(submodifier);
      });
      this.selectedItems = subMods;
    }

    let temp: any = [];
    // If customize, check nested option id's instead
    if (this.optionGroup.description.toLowerCase() === 'customize') {
      temp = this.updateCustomizeControlArray(arr);
    } else {
      this.optionGroup.options.forEach(option => {
        if (!this.oneChoice) {
          temp.push(arr.includes(option.id));
        } else {
          if (arr.includes(option.id) && this.option.value !== option.id) {
            this.option.setValue(option.id);
          }
        }
      });
    }
    if (!this.oneChoice || this.optionGroup.description.toLowerCase() === 'customize') {
      this.options.setValue(temp);
    }
  }

  subscribeToFormChanges() {
    if (this.oneChoice) {
      this.subs.push(
        this.option.valueChanges.subscribe(val => {
          // Update array in service
          // Remove all options, add val
          if (this.isSaladSize) {
            const temp = [];
            temp.push(val);
            const customize = this.optionGroup.options
              .find(o => o.id === val)
              .modifiers.find(m => m.description.toLowerCase().includes('customize'));
            temp.push(...customize.options.map(o => o.id));
            this.service.setSelected(temp);
          } else {
            this.optionGroup.options.forEach(option => {
              this.service.removeFromSelected(option.id);
            });
            this.service.addToSelected(val);
          }
        })
      );
    } else {
      this.subs.push(
        this.options.valueChanges.subscribe(val => {
          const enzoIndex = this.optionGroup.options.findIndex(o =>
            o.name.toLowerCase().includes(this.enzoName.toLowerCase())
          );
          if (enzoIndex > -1) {
            this.enzoSelected = val[enzoIndex];
          }
          if (this.enzoSelected) {
            this.service.addToSelected(this.optionGroup.options[enzoIndex].id);
            this.optionGroup.options.forEach(option => {
              if (option.metadata?.find(m => m.key === 'allowedWithGluten' && m.value === 'false')) {
                this.service.removeFromSelected(option.id);
              }
            });
          }
          // Update array in service
          // Remove if false, add if true
          const arr = this.service.getSelected();
          let i = 0;
          this.options.value.forEach((controlVal: boolean) => {
            let id: number;
            if (this.optionGroup.description.toLowerCase() === 'customize') {
              const modifier = this.optionGroup.options.find(el => el.name.toLowerCase().includes('additional'))
                .modifiers[i];
              id = modifier.options[0].id;
              modifier.options.forEach(opt => {
                if (arr.includes(opt.id)) {
                  id = opt.id;
                }
              });
            } else {
              id = this.optionGroup.options[i].id;
            }
            if (controlVal) {
              this.service.addToSelected(id);
            } else {
              this.service.removeFromSelected(id);
            }
            i++;
          });
        })
      );
    }
  }

  subscribeToSelected() {
    this.subs.push(
      this.service.selected.subscribe(() => {
        const arr = this.service.getSelected();

        // Set form values based on what is in arr
        // If it is, set to true
        let temp: any = [];
        // If customize, check nested option id's instead
        if (this.optionGroup.description.toLowerCase() === 'customize') {
          temp = this.updateCustomizeControlArray(arr);
        } else {
          this.optionGroup.options.forEach(option => {
            if (!this.oneChoice) {
              temp.push(arr.includes(option.id));
            } else {
              if (arr.includes(option.id) && this.option.value !== option.id) {
                this.option.setValue(option.id);
              }
            }
          });
        }
        if (!this.oneChoice || this.optionGroup.description.toLowerCase() === 'customize') {
          this.options.setValue(temp);
        }

        // Update array to send down what submodifier was selected
        const subMods: any[] = [];
        const addditional = this.optionGroup.options.find(el => el.name.toLowerCase().includes('additional'));
        if (addditional) {
          addditional.modifiers.forEach(innerGroup => {
            let submodifier: any;
            innerGroup.options.forEach(mod => {
              if (arr.includes(mod.id)) {
                submodifier = mod;
              }
            });
            subMods.push(submodifier);
          });
          this.selectedItems = subMods;
        }
      })
    );
  }

  get options() {
    return this.form.get('options') as UntypedFormArray;
  }

  get option() {
    return this.form.get('option') as UntypedFormControl;
  }

  toFormGroup(optionGroup: OptionGroup) {
    this.formVals = this.optionGroup.options.map(opt => opt.id);
    this.formText = this.optionGroup.options.map(opt => opt.name);
    this.formCosts = this.optionGroup.options.map(opt => opt.cost);
    if (this.oneChoice) {
      const val = this.getDefaultVal(optionGroup);
      return new UntypedFormGroup({
        option: new UntypedFormControl(val)
      });
    } else {
      return new UntypedFormGroup({
        options: this.buildOptionsCheckbox(optionGroup)
      });
    }
  }

  buildOptionsCheckbox(optionGroup: OptionGroup) {
    const arr = optionGroup.options.map(option => {
      return new UntypedFormControl(this.service.hasOption(option.id));
    });
    return new UntypedFormArray(arr);
  }

  getDefaultVal(optionGroup: OptionGroup): number {
    if (this.isEdit) {
      let result = optionGroup.options.find(opt => opt.name.toLowerCase().includes('no ')).id;
      optionGroup.options.forEach(option => {
        if (this.service.hasOption(option.id)) {
          result = option.id;
        }
      });
      return result;
    } else {
      optionGroup.options.forEach(option => {
        if (option.isdefault) {
          return option.id;
        }
      });
      if (optionGroup.description.toLowerCase().includes('size')) {
        return optionGroup.options[0].id;
      }
      return optionGroup.options.find(opt => opt.name.toLowerCase().includes('no ')).id;
    }
  }

  /*
   * Custom functions for handling Punch's nested customize options
   */

  createCustomizeForm(optionGroup: OptionGroup) {
    return new UntypedFormGroup({
      options: this.buildCustomizeOptions(optionGroup)
    });
  }

  buildCustomizeOptions(optionGroup: OptionGroup) {
    const arr: UntypedFormControl[] = [];
    const tempVals: number[] = [];
    const tempText: string[] = [];
    const tempCosts: number[] = [];
    const subModifiers: any[] = [];
    const modifierGroup: any[] = [];
    const addditional = optionGroup.options.find(el => el.name.toLowerCase().includes('additional'));
    addditional.modifiers.forEach(innerGroup => {
      const addOption = innerGroup.options.find(opt => opt.name.includes('Add '));
      arr.push(new UntypedFormControl(this.service.hasOption(addOption.id)));
      tempVals.push(addOption.id);
      tempText.push(innerGroup.description);
      tempCosts.push(addOption.cost);
      subModifiers.push(innerGroup.options);
      modifierGroup.push(innerGroup);
    });
    this.formVals = tempVals;
    this.formText = tempText;
    this.formCosts = tempCosts;
    this.subModifiers = subModifiers;
    this.modifierGroup = modifierGroup;
    return new UntypedFormArray(arr);
  }

  updateCustomizeControlArray(selectedArr: any) {
    const temp: any = [];
    const addditional = this.optionGroup.options.find(el => el.name.toLowerCase().includes('additional'));
    addditional.modifiers.forEach(innerGroup => {
      let includesItem = false;
      innerGroup.options.forEach(opt => {
        if (selectedArr.includes(opt.id)) {
          includesItem = true;
        }
      });
      temp.push(includesItem);
    });
    return temp;
  }

  testFunction(options: any) {}
  optionInSelected(optionId: number) {
    return this.service.getSelected().includes(optionId);
  }

  modifyClicked(index: any) {
    const arr = this.service.getSelected();
    this.editSelectedGroup = this.modifierGroup[index];
    this.open(this.content);
  }

  open(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'informationModal', centered: true });
    setTimeout(() => {
      if (this.store.selectSnapshot(state => state.app.accessibilityMode)) {
        const modal = document.getElementsByClassName('modal-dialog-centered').item(0);
        modal.classList.add('d-none');
        modal.className = modal.className.replace('modal-dialog-centered', 'accessibility-transition');
        modal.classList.add('modal-dialog-end');
        modal.classList.remove('d-none');
      }
    });
  }

  addCustomization(i: any) {
    this.selectedSubModifier = i;
  }

  submitCustomization() {
    if (this.selectedSubModifier != null) {
      const id = this.editSelectedGroup.options[this.selectedSubModifier].id;
      const arr = this.service.getSelected();
      this.editSelectedGroup.options.forEach((opt: any) => {
        if (arr.includes(opt.id)) {
          this.service.removeFromSelected(opt.id);
        }
      });
      this.service.addToSelected(id);
      this.selectedSubModifier = null;
      this.modalService.dismissAll();
    }
  }

  sectionClicked() {
    this.isChecked = false;
  }

  sectionUnclicked() {
    this.isChecked = true;
  }
}
